$(document).ready(function () {

  $(document).foundation();

  if ($('#mapCanvas').length > 0) {
    window.onload = initializeGoogleMap();
  }
  $('.slick-slider').slick({
    lazyLoad: 'ondemand'
  });

});