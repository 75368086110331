// JavaScript Document

// BeginOAWidget_Instance_2187524: #mapCanvas

// initialize the google Maps

function initializeGoogleMap() {
  // set latitude and longitude to center the map around
  var latlng = new google.maps.LatLng(50.816863, 20.621552);

  // set up the default options
  var myOptions = {
    zoom: 14,
    center: latlng,
    navigationControl: true,
    navigationControlOptions: {
      style: google.maps.NavigationControlStyle.DEFAULT,
      position: google.maps.ControlPosition.TOP_LEFT
    },
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: google.maps.MapTypeControlStyle.DEFAULT,
      position: google.maps.ControlPosition.TOP_RIGHT
    },

    scaleControl: true,
    scaleControlOptions: {
      position: google.maps.ControlPosition.BOTTOM_LEFT
    },
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    draggable: true,
    disableDoubleClickZoom: false,
    keyboardShortcuts: true
  };
  var map = new google.maps.Map(document.getElementById("mapCanvas"), myOptions);
  if (false) {
    var trafficLayer = new google.maps.TrafficLayer();
    trafficLayer.setMap(map);
  }
  if (false) {
    var bikeLayer = new google.maps.BicyclingLayer();
    bikeLayer.setMap(map);
  }
  if (true) {
    addMarker(map, 50.817175, 20.621120, "We are here");
  }
}

// Add a marker to the map at specified latitude and longitude with tooltip
function addMarker(map, lat, long, titleText) {
  var markerLatlng = new google.maps.LatLng(lat, long);
  var marker = new google.maps.Marker({
    position: markerLatlng,
    map: map,
    title: "We are here",
    icon: ""
  });
}


// EndOAWidget_Instance_2187524